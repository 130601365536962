import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  tip: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
};

const defaultProps = {
  tip: null,
  label: null,
  name: null,
};

function Label(props) {
  const { label, tip, name } = props;

  if (!label) return null;

  return (
    <label htmlFor={name} className={`label control-label${tip ? ' required' : ''}`} title={label}>
      {label}
    </label>
  );
}

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;

export default Label;
