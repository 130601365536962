import { memoize } from 'utils/helpers';
import { cleanText } from 'utils/form/normalizations';
import Cpf from 'cpf';

export const required = (value) => {
  if (value !== null && value !== undefined) {
    if (typeof value === 'string' && value.trim().length > 0) {
      return undefined;
    }
    if (typeof value === 'number') return undefined;
    if (Array.isArray(value) && value.length > 0) return undefined;
    if (typeof value === 'object' && (Object.keys(value).length > 0 || value instanceof File)) {
      return undefined;
    }
    if (typeof value === 'boolean' && value) return undefined;
  }

  return { id: 'shared.form.errors.required' };
};

export const requiredSubject = (value) => {
  if (value !== null && value !== undefined) {
    if (typeof value === 'string' && value.trim().length > 0) {
      return undefined;
    }
    if (typeof value === 'number') return undefined;
    if (Array.isArray(value) && value.length > 0) return undefined;
    if (typeof value === 'object' && (Object.keys(value).length > 0 || value instanceof File)) {
      return undefined;
    }
    if (typeof value === 'boolean' && value) return undefined;
  }

  return { id: 'shared.form.errors.required_subject' };
};

export const requiredMessage = (value) => {
  if (value !== null && value !== undefined) {
    if (typeof value === 'string' && value.trim().length > 0) {
      return undefined;
    }
    if (typeof value === 'number') return undefined;
    if (Array.isArray(value) && value.length > 0) return undefined;
    if (typeof value === 'object' && (Object.keys(value).length > 0 || value instanceof File)) {
      return undefined;
    }
    if (typeof value === 'boolean' && value) return undefined;
  }

  return { id: 'shared.form.errors.required_message' };
};

export const returnCustomError = error => (value) => {
  if (error !== null && error !== undefined) {
    return error;
  }

  return undefined;
};

export const firewallUnsupportedCharacters = (value) => {
  if (value) {
    const regex = new RegExp(/(^\+\s+)|(\s\+)/g);
    const ocurrences = new Set(value.match(regex));
    const unsupportedCharacters = Array.from(ocurrences).join(', ');

    return value && ocurrences.size != 0
      ? { id: 'shared.form.errors.not_permited_characters', values: { characters: unsupportedCharacters } }
      : undefined;
  }
}

export const isNumberNormalized = value =>
  value && (!/^[0-9]+$/.test(value)
    ? { id: 'shared.form.errors.number_normalized' }
    : undefined);

export const minLength = min => value =>
  value &&
    (value.length < min
      ? { id: 'shared.form.errors.min_length', values: { min } }
      : undefined
    );

export const maxLength = max => value =>
  value &&
    (value.length > max
      ? { id: 'shared.form.errors.max_length', values: { max } }
      : undefined
    );

export const number = value =>
  value && (Number.isNaN(Number(value))
    ? { id: 'shared.form.errors.number' }
    : undefined);

export const isInteger = value =>
  value && (value % 1 === 0
    ? undefined
    : { id: 'shared.form.errors.integer' });

export const minValue = min => value =>
  value && (parseInt(value) < parseInt(min)
    ? { id: 'shared.form.errors.min_value', values: { min } }
    : undefined);

export const maxValue = max => value =>
  value && (parseInt(value) > parseInt(max)
    ? { id: 'shared.form.errors.max_value', values: { max } }
    : undefined);

export const maxFileSize = maxfile => (value) => {
  let message;
  if (value && value.length > 1) {
    for (let i = 0; i < value.length; i += 1) {
      if (value[i].size > maxfile * 1000 * 1000) {
        message = { id: 'shared.common_components.one_or_more_files_too_big', values: { max: maxfile } };
        break;
      }
    }
  } else {
    message = value && (value.size > maxfile * 1000 * 1000
      ? { id: 'shared.common_components.file_too_big', values: { size: Math.round(value.size / 1000 / 1000), max: maxfile } }
      : undefined);
  }

  return message;
};

export const maxTotalSize = maxTotal => (value) => {
  let message;
  if (value && maxTotal) {
    const totalSize = value.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > maxTotal * 1000 * 1000) {
      message = { id: 'shared.common_components.files_too_big', values: { size: Math.round(totalSize / 1000 / 1000), max: maxTotal } };
    }
  }

  return message;
};

export const email = value =>
  value && (!/^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/.test(value)
    ? { id: 'shared.form.errors.email' }
    : undefined);

export const phone = value =>
  value && (/^(\+\(?[0-9]{1,3}\)? ?)?[0-9 .-]{7,}[0-9]$/.test(value)
    ? undefined
    : { id: 'shared.form.errors.phone' });

const checkType = (value, acepted) => {
  const acceptedFiles = acepted.split(',');
  const mimeType = value.type;
  const baseMimeType = mimeType.replace(/\/.*$/, '');
  let message;

  for (let validType of acceptedFiles) {
    validType = validType.trim();

    if (validType.charAt(0) === '.') {
      if (value.name.toLowerCase().includes(
        validType.toLowerCase(),
        value.name.length - validType.length,
      )) return undefined;
    } else if (/\/\*$/.test(validType)) {
      if (baseMimeType === validType.replace(/\/.*$/, '')) return undefined;
    } else if (mimeType === validType) return undefined;

    message = { id: 'shared.form.errors.asset_var', values: { assets: acepted } };
  }

  return message;
};

export const aceptedFiles = acepted => (value) => {
  let message;

  if (value && acepted) {
    if (value.length > 1) {
      for (let i = 0; i < value.length; i += 1) {
        message = checkType(value[i], acepted);
        if (message !== undefined) break;
      }
    } else {
      message = checkType(value, acepted);
    }
  }

  return message;
};


export const isAsset = (value) => {
  const validAssets = [
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/doc',
    'application/odt',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/docx',
  ];
  const valueToCheck = (value && value.type) ? value.type : value;

  return value && (validAssets.indexOf(valueToCheck) !== -1
    ? undefined
    : { id: 'shared.form.errors.asset' });
};

export const image = value =>
  value && (value.type === 'image/jpg' || value.type === 'image/jpeg' || value.type === 'image/png'
    ? undefined
    : { id: 'shared.form.errors.image' });

export const imageSize = value =>
  value && (value.size < 10_485_760
    ? undefined
    : { id: 'shared.form.errors.image_size' });

export const date = (value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  const daysMonths = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  let values = '';
  if (value instanceof Object) {
    values = value.format('YYYY-MM-DD').split('-');
  } else {
    values = value.split('-');
  }

  // Check leap-year
  if ((((values[0] % 100) !== 0) && ((values[0] % 4) === 0)) || ((values[0] % 400) === 0)) {
    daysMonths[2] = 29;
  }

  return (values[2] > daysMonths[values[1]])
    ? { id: 'shared.form.errors.date' }
    : undefined;
};

export const minDate = memoize(min => (value) => {
  const minDt = min ? new Date(min) : new Date();

  return value &&
    (new Date(value) <= minDt
      ? { id: 'shared.form.errors.min_date', values: { min: minDt.toISOString().slice(0, 10) } }
      : undefined
    );
});

export const age = (value) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  const parts = value.split('-');
  const now = new Date();
  const birthday = new Date(now.getFullYear(), parts[1] - 1, parts[2]);
  let years = now.getFullYear() - parts[0];
  if (now < birthday) years -= 1;

  return (years < 16)
    ? { id: 'shared.form.errors.age' }
    : undefined;
};

export const nif = (value) => {
  let letters = 'TRWAGMYFPDXBNJZSQVHLCKET';

  let integer = parseInt(/^[0-9]+/.exec(value), 10);
  const letter = /[A-Za-z]$/.exec(value) ? /[A-Za-z]$/.exec(value).toString() : '';
  integer %= 23;
  letters = letters.substring(integer, integer + 1);

  return (letters === letter.toUpperCase())
    ? undefined
    : { id: 'shared.form.errors.nif' };
};

export const validateEncoding = memoize(regexString => (value) => {
  const regex = new RegExp(`${regexString}`);
  const char = value.match(regex);

  return value && (char
    ? { id: 'shared.form.errors.encoding', values: { char: char[0], index: char.index } }
    : undefined);
});

export const validateCleanTextEncoding = memoize(regexString => (value) => {
  const regex = new RegExp(`${regexString}`);
  const char = cleanText(value).match(regex);

  return value && (char
    ? { id: 'shared.form.errors.encoding', values: { char: char[0], index: char.index } }
    : undefined);
});

export const unique = memoize(values => value =>
  value && (values.includes(value)
    ? { id: 'shared.form.errors.not_unique' }
    : undefined));

export const url = (value) => {
  const urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

  return (value && (!urlRegExp.test(value))
    ? { id: 'shared.form.errors.url' }
    : undefined);
};

export const skypeUsername = (value) => {
  const regex = new RegExp('^[A-Za-z][A-Za-z0-9_:,.]{5,31}$');
  return (value && !regex.test(value)
    ? { id: 'shared.form.errors.skypeUsername' }
    : undefined);
};

export const wordCharacter = (value) => {
  const regexp = value.match(/\W/);
  return value && (regexp
    ? { id: 'shared.form.errors.wordCharacter' }
    : undefined);
};

const plainTextLength = (value) => {
  let normalizedText = value;

  if (normalizedText) {
    normalizedText = normalizedText.replace(/<ul.*?>((.|\s)*?)<\/ul>/gm, ul => ul.replace(/[\n]+/gm, '\n'));
    normalizedText = normalizedText.replace(/<ol.*?>((.|\s)*?)<\/ol>/gm, ol => ol.replace(/[\n]+/gm, '\n'));

    // countOrderedList
    normalizedText = normalizedText.replace(/<ol(.*?)>((.|\s)*?)<\/ol>/gm, (ol) => {
      let numElement = 0;
      return ol.replace(/<li>/gm, () => {
        numElement += 1;
        return `${numElement}. `;
      });
    });

    // countUnorderedList
    normalizedText = normalizedText.replace(/<li>/gm, '* ');

    // countHrefLinks
    normalizedText = normalizedText.replace(/<a(.*?)href=['"](.*?)['"](.*?)<\/a>/gm, '$2');

    // countLineBreaks
    normalizedText = normalizedText.replace(/(\r\n|\n|\r)/gm, ' ');
    normalizedText = normalizedText.replace(/<\/p>$/gm, '');
    normalizedText = normalizedText.replace(/<\/p><ol>/gm, ' ');
    normalizedText = normalizedText.replace(/<\/p><ul>/gm, ' ');
    normalizedText = normalizedText.replace(/<\/p>/gm, '  ');
    normalizedText = normalizedText.replace(/<\/ol><p>/gm, '  ');
    normalizedText = normalizedText.replace(/<\/ul><p>/gm, '  ');

    // countIndentation
    normalizedText = normalizedText.replace(/<div .*?>/gm, '\t');
    normalizedText = normalizedText.replace(/<p .*?>/gm, '\t');

    // stripHtmlTags
    const tmp = document.createElement('div');

    tmp.innerHTML = normalizedText;

    if (tmp.textContent === '' && typeof tmp.innerText === 'undefined') {
      normalizedText = '';
    } else {
      normalizedText = tmp.textContent || tmp.innerText;
    }

    normalizedText = normalizedText.replace(/^([\t\r\n]*)$/, '');

    // trim last spaces
    normalizedText = normalizedText.replace(/\s*$/, '');

    return normalizedText.length;
  }
};

export const minLengthPlainText = memoize(min => (value) => {
  const sizeCleaned = plainTextLength(value);
  return (value && sizeCleaned < min
    ? { id: 'shared.form.errors.min_length', values: { min } }
    : undefined
  );
});

export const maxLengthPlainText = memoize(max => (value) => {
  const sizeCleaned = plainTextLength(value);
  return (value && sizeCleaned > max
    ? { id: 'shared.form.errors.max_length', values: { max } }
    : undefined
  );
});

export const VideoYouTubeVimeo = (value) => {
  const regex = new RegExp('^(https?:\\/\\/)(vimeo.com|youtu.be|www.youtube.com)\\/([\\w/]+)([?].*)?$');
  return (value && !regex.test(value)
    ? { id: 'shared.form.errors.VideoYouTubeVimeo' }
    : undefined);
};

export const cpfBrValidation = (value) => {
  try {
    const formattedCpf = Cpf.format(value);

    return (Cpf.isValid(formattedCpf) ? undefined : { id: 'shared.form.errors.cpf_br_character' });
  } catch (error) {
    return { id: 'shared.form.errors.cpf_br_format' };
  }
};

export const dniValidation = (value) => {
  const regex = new RegExp(/^\d{8}-[a-zA-Z]$/);
  const letterValues = 'TRWAGMYFPDXBNJZSQVHLCKET';

  if (regex.test(value)) {
    const splittedDni = value.split('-');
    const dniNumber = splittedDni[0];
    const dniCharValue = splittedDni[1];
    const validLetter = letterValues.substring((dniNumber % 23), (dniNumber % 23) + 1);
    if (validLetter !== dniCharValue.toUpperCase()) {
      return { id: 'shared.form.errors.dni_character' };
    }
    return undefined;
  }
  return (value ? { id: 'shared.form.errors.dni_format' } : undefined);
};

export const nieValidation = (value) => {
  const regex = new RegExp(/^[XYZ]{1}[0-9]{7}-[a-zA-Z]$/);
  const letterValues = 'TRWAGMYFPDXBNJZSQVHLCKET';
  if (regex.test(value)) {
    const splittedNie = value.split('-');
    let nieNumber = splittedNie[0];
    const nieCharValue = splittedNie[1];
    nieNumber = nieNumber.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');
    const validLetter = letterValues.substring((nieNumber % 23), (nieNumber % 23) + 1);
    if (validLetter !== nieCharValue.toUpperCase()) {
      return { id: 'shared.form.errors.nie_character' };
    }
    return undefined;
  }
  return (value ? { id: 'shared.form.errors.nie_format' } : undefined);
};

export const rutFormat = (value) => {
  const regex = new RegExp(/^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/);
  return (value && !regex.test(value)
    ? { id: 'shared.form.errors.rut_format' }
    : undefined);
};

export const rutCodeValidation = (value) => {
  if (value) {
    const splittedRut = value.split('-');
    let rutNumber = splittedRut[0];
    const rutDigValue = (splittedRut[1] === 'k' ? 'K' : splittedRut[1]);
    let M = 0;
    let S = 1;

    for (; rutNumber; rutNumber = Math.floor(rutNumber / 10)) {
      S = (S + ((rutNumber % 10) * (9 - (M % 6)))) % 11;
      M += 1;
    }

    const validDigValue = S ? S - 1 : 'K';

    return (validDigValue.toString() === rutDigValue
      ? undefined
      : { id: 'shared.form.errors.rut_digit' });
  }

  return undefined;
};

export const validateRequiredVariablesEmail = urlType => (value) => {
  const urlTypeRegex = new RegExp(`{{\\s*${urlType}\\s*}}`);
  const lastDateRegex = new RegExp(/{{\s*last_date\s*}}/);

  return (urlTypeRegex.test(value) && lastDateRegex.test(value)
    ? undefined
    : { id: 'candidate_forms.errors.body.missing_sensitive_variables', values: { url: urlType } });
};

export const dateGreaterThan = diff => value =>
  value && (diff >= 0
    ? { id: 'shared.form.errors.date_must_be_later' }
    : undefined);

export const hiredStatus = memoize(hiredStatusIds => (value) => {
  return (hiredStatusIds.includes(value) ? { id: 'candidates.modals.export_to_hris.errors.invalid_hired_status' } : undefined);
});
