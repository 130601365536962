import React from 'react';
import { FormattedMessage } from 'react-intl';

function ErrorMessage({ touched, error, warning }) {
  if (touched) {
    if (error) {
      const renderError = () => {
        if(typeof error === 'string') {
          return error
        } else if (Array.isArray(error)){
          return error.join(', ')
        } else {
          return <FormattedMessage {...error} />
        }
      }

      return (
        <span className="error">
          { renderError() }
        </span>
      );
    }
    if (warning) return <span>{warning}</span>;
  }

  return null;
}

export default ErrorMessage;
