import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  hint: PropTypes.node,
  label: PropTypes.node,
  labelTip: PropTypes.string,
  parentCallback: PropTypes.func,
  reference: PropTypes.func,
  type: PropTypes.string,
  dsInputRadio: PropTypes.bool,
};

const defaultProps = {
  className: null,
  id: null,
  hint: null,
  label: null,
  labelTip: null,
  parentCallback: null,
  reference: null,
  type: null,
  dsInputRadio: false,
};

class RenderField extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { checked, value, type } = e.target;
    const { parentCallback, input: { onChange } } = this.props;
    const inputValue = type === 'checkbox' ? checked : value;

    onChange(inputValue);

    // Use only if you have to change the parent component
    if (parentCallback) {
      parentCallback(inputValue);
    }
  }

  render() {
    const {
      input: { onBlur, value },
      meta: { error, touched, warning },
      label, labelTip, type, hint, parentCallback,
      className, id, reference, dsInputRadio, meta,
      ...rest
    } = this.props;

    const textareaType =
      (<textarea
        {...this.props.input}
        onBlur={() => onBlur(value)}
        id={id}
        onChange={this.handleChange}
        ref={reference}
        {...rest}
      />);
    const inputType =
      (<input
        {...this.props.input}
        className="form-control"
        id={id}
        onBlur={() => onBlur(value)}
        onChange={this.handleChange}
        ref={reference}
        type={type}
        {...rest}
      />);
    const elementType = type === null ? textareaType : inputType;
    if (dsInputRadio) {
      return (
        <div className={`${className} ${touched && error ? 'error' : ''}`}>
          {elementType}
          <Label label={label} tip={labelTip} name={id} />
          {warning && <span className="warn">{warning}</span>}
          {
            touched && error &&
              <span className="error">
                {typeof error === 'string' ? error : <ErrorMessage {...meta} />}
              </span>
          }
        </div>
      );
    }
    return (
      <div className={`${className} ${touched && error ? 'error' : ''}`}>
        <Label label={label} tip={labelTip} name={id} />
        <div className="field-container">
          {elementType}
          {warning && <span className="warn">{warning}</span>}
          {
            touched && error &&
              <span className="error">
                {typeof error === 'string' ? error : <ErrorMessage {...meta} />}
              </span>
          }
        </div>
        {hint && <span className="hint">{hint}</span>}
      </div>
    );
  }
}

RenderField.propTypes = propTypes;
RenderField.defaultProps = defaultProps;

export default RenderField;
